import { APP_NAME } from "../../../constants";

/** 어드민에는 표시가 불필요한 쉽다 웹 전용 안내 메세지 */
const shipdaMessage =
  APP_NAME === "shipda-kr" ? "운영매니저에게 문의해주세요." : "";

type ErrorCodeToMessageMap = Record<
  string,
  { messageType: "titleOnly"; title: string }
>;

/** 쉽다 웹, 어드민 공통 에러코드
 * (failureInfo 내 error 로 에러메시지를 보내주는 것으로 변경되어 기본 메시지만 남겨둠)
 */
const GET_FREE_TIME_ERROR_CODE: ErrorCodeToMessageMap = {
  default: {
    messageType: "titleOnly",
    title: `프리타임 조회 중 오류가 발생했습니다. ${shipdaMessage}`,
  },
};

export { GET_FREE_TIME_ERROR_CODE };
